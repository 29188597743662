.border {
    border-width: 1px;
}
.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.p-4 {
    padding: 1rem;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.border {
    border-width: 1px;
}
.rounded-\[20px\] {
    border-radius: 20px;
}
.w-\[402px\] {
    width: 402px;
}
.h-max {
    height: max-content;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.pt-2 {
    padding-top: 0.5rem;
}
.pb-2 {
    padding-bottom: 0.5rem;
}
.flex-col {
    flex-direction: column;
}
.text-\[\#404C55\] {
    --tw-text-opacity: 1;
    color: rgb(64 76 85 / var(--tw-text-opacity));
}
.font-bold {
    font-weight: 700;
}
.text-\[38px\] {
    font-size: 38px;
}
.mb-5 {
    margin-bottom: 1.25rem;
}
blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
    margin: 0;
}
.text-\[14px\] {
    font-size: 14px;
}
.mb-7 {
    margin-bottom: 1.75rem;
}
.mb-5 {
    margin-bottom: 1.25rem;
}
img, video {
    max-width: 100%;
    height: auto;
    display: block;
    vertical-align: middle;
}
.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.rounded-\[20px\] {
    border-radius: 20px;
}
.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
ol, ul, menu {
    list-style: none;
    margin: 0;
    padding: 0;
}
.justify-between {
    justify-content: space-between;
}
.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
}
.font-bold {
    font-weight: 700;
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.pt-3 {
    padding-top: 0.75rem;
}
.pr-3 {
    padding-right: 0.75rem;
}
.pl-3 {
    padding-left: 0.75rem;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.p-3 {
    padding: 0.75rem;
}
.md\:w-\[604px\] {
    width: 604px;
}
.sm\:p-6 {
    padding: 1.5rem;
}
.md\:w-\[500px\] {
    width: 500px;
}

.ml-3 {
    margin-left: 0.75rem;
}
.mr-4 {
    margin-right: 1rem;
}