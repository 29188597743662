.modal-overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(64, 76, 85, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-overlay .modal-content {
    background-color: white;
    border-radius: 20px;
    padding: 15px 22px;
    color: #404C55;
    min-width: 262px;
    max-width: 100%;
    text-align: center;
    position: relative;
}
.modal-overlay .modal-content .close {
    position: absolute;
    top: 10px;
    right: 15px;
}
.modal-overlay .modal-content img.warning {
    width: 32px;
    height: auto;
    margin: 0 auto 16px;
}
.modal-overlay .modal-content p {
    margin: 0;
}