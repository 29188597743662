$black: #404C55;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.wrapper {
  background-color: #404C55;
  background-image: url('./assets/images/bg-wave.png'), 
    url('./assets/images/bg-illustration.png'), 
    url('./assets/images/bg-pattern.png');
  background-position: bottom left,
    top right,
    top left;
  background-size: 100% auto,
    1268px auto,
    100% auto;
  background-repeat: no-repeat;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.avax {
  width: 32px;
  height: auto;
}

.modal-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    background-color: white;
    border-radius: 20px;
    padding: 15px 22px;
    color: $black;
    min-width: 262px;
    max-width: 100%;
    text-align: center;
    position: relative;

    .close {
      position: absolute;
      top: 10px;
      right: 15px;
    }

    img.warning {
      width: 32px;
      height: auto;
      margin: 0 auto 16px;
    }

    p {
      margin: 0;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
