.w-full {
    width: 100%;
}
.h-12 {
    height: 3rem;
}
.z-10 {
    z-index: 10;
}
.left-0 {
    left: 0px;
}
.top-0 {
    top: 0px;
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.p-12 {
    padding: 3rem;
}
.gap-7 {
    gap: 1.75rem;
}
.justify-between {
    justify-content: space-between;
}
.items-center {
    align-items: center;
}
.h-full {
    height: 100%;
}
.flex {
    display: flex;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
img, video {
    max-width: 100%;
    height: auto;
}
.flex {
    display: flex;
}
.gap-5 {
    gap: 1.25rem;
}
.flex-row {
    flex-direction: row;
}
.flex {
    display: flex;
}
ol, ul, menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.avax {
    width: 32px;
    height: auto;
}
.items-center {
    align-items: center;
}
.text-\[14px\] {
    font-size: 14px;
}
.text-center {
    text-align: center;
}
.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.items-center {
    align-items: center;
}
.inline-flex {
    display: inline-flex;
}

button, [role="button"] {
    cursor: pointer;
}
button, select {
    text-transform: none;
}
button, input, optgroup, select, textarea {
    font-family: inherit;
    color: inherit;
    margin: 0;
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
}
.h-9 {
    height: 2.25rem;
}
.border {
    border-width: 1px;
}
.rounded-\[10px\] {
    border-radius: 10px;
}
.border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity));
}