.sm\:p-6 {
    padding: 1.5rem;
}
.rounded-\[20px\] {
    border-radius: 20px;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.h-max {
    height: max-content;
}
.border {
    border-width: 1px;
}
.md\:w-\[804px\] {
    width: 804px;
}
.flex-col {
    flex-direction: column;
}
.pb-2 {
    padding-bottom: 0.5rem;
}
.pt-2 {
    padding-top: 0.5rem;
}
.flex {
    display: flex;
}
.items-center {
    align-items: center;
}
.font-bold {
    font-weight: 700;
}
.mb-5 {
    margin-bottom: 1.25rem;
}
.text-\[38px\] {
    font-size: 38px;
}
.text-\[\#404C55\] {
    --tw-text-opacity: 1;
    color: rgb(64 76 85 / var(--tw-text-opacity));
}
.mb-7 {
    margin-bottom: 1.75rem;
}
.text-\[14px\] {
    font-size: 14px;
}
.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.md\:w-\[700px\] {
    width: 700px;
}
.w-full {
    width: 100%;
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}
table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.text-\[\#404C55\] {
    --tw-text-opacity: 1;
    color: rgb(64 76 85 / var(--tw-text-opacity));
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-left {
    text-align: left;
}
.overflow-x-auto {
    overflow-x: auto;
}
.relative {
    position: relative;
}
.flex-row {
    flex-direction: row;
}
.justify-end {
    justify-content: flex-end;
}